import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Button = (props) => {
  const className = [props.className];

  let theme =
    'inline-flex h-9 w-28 cursor-pointer items-center justify-center rounded border px-5 py-3 font-bold transition duration-300 ease-in-out hover:text-base-background';

  if (props.isPrimary) theme += ' border-primary hover:bg-primary';
  else theme += ' border-secondary hover:bg-secondary';

  const onClick = () => {
    if (props.onClick) props.onClick();
  };

  if (props.type === 'link') {
    if (props.isExternal) {
      return (
        <Link
          to={props.href}
          className={[theme, className].join(' ')}
          target="_blank"
          rel="noopener"
        >
          {props.children}
        </Link>
      );
    }

    return (
      <Link to={props.href} className={[theme, className].join(' ')}>
        {props.children}
      </Link>
    );
  }

  return (
    <button
      type={props.type}
      className={[theme, className].join(' ')}
      onClick={onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;

Button.defaultProps = {
  type: 'button',
  isPrimary: true,
};

Button.propTypes = {
  type: propTypes.oneOf(['button', 'link']),
  onClick: propTypes.func,
  href: propTypes.string,
  className: propTypes.string,
  isPrimary: propTypes.bool,
  isExternal: propTypes.bool,
};
