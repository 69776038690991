// components
import CardMember from 'components/card-member/CardMember';
import Heading from 'components/heading/Heading';
import Section from 'components/section/Section';
// data
import members from 'data/members.json';
import { Helmet } from 'react-helmet';
import transition from 'transition';

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Aurora Web</title>
        <meta 
          name="description" 
          content="We are Aurora Web, experts in UI/UX design, mobile app development, and web development. Our passion lies in transforming ideas into outstanding digital solutions."
        />
      </Helmet>
      <Section id="about" className="pt-40">
        <Heading
          title="About Us"
          description={
            <>
              We are Aurora Web, experts in UI/UX design, mobile app <br />
              development, and web development. Our passion lies in <br />
              transforming ideas into outstanding digital solutions.
            </>
          }
        />
        <div className="grid grid-cols-1 gap-5 lg:grid-cols-3">
          {members.map((member, index) => (
            <CardMember key={`member-${index}`} member={member} />
          ))}
        </div>
      </Section>
    </>
  );
};

export default transition(About);
