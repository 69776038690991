import propTypes from 'prop-types';

const Section = (props) => {
  const { id, className, children } = props;

  return (
    <section
      id={id}
      className={['px-5 md:px-10 lg:px-20', className].join(' ')}
    >
      {children}
    </section>
  );
};

export default Section;

Section.propTypes = {
  id: propTypes.string.isRequired,
  className: propTypes.string,
};
