import propTypes from 'prop-types';

const CardWhy = (props) => {
  const { title, description, illustration } = props;

  return (
    <div className="card flex flex-col justify-between">
      <div>
        <h2 className="mb-3 text-4xl font-bold">{title}</h2>
        <p className="mb-5 text-base-text opacity-60">{description}</p>
      </div>
      <div className="flex justify-center">{illustration}</div>
    </div>
  );
};

export default CardWhy;

CardWhy.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  illustration: propTypes.element.isRequired,
};
