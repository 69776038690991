import Footer from 'components/footer/Footer';
import Navbar from 'components/navbar/Navbar';
import { Outlet } from 'react-router';

const Default = (props) => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

export default Default;
