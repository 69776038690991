import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ProjectItem = (props) => {
  const { title, description, alignRight, isLink } = props;

  const align = alignRight ? 'text-left md:text-right' : 'text-left';

  return (
    <div>
      <h3 className={`mb-2 text-xl font-bold ${align}`}>{title}</h3>
      {isLink ? (
        <Link
          className={`block font-bold opacity-60 ${align}`}
          to={description}
          target="_blank"
          rel="noopener noreferrer"
        >
          {description}
        </Link>
      ) : (
        <span className={`block font-bold opacity-60 ${align}`}>
          {description}
        </span>
      )}
    </div>
  );
};

export default ProjectItem;

ProjectItem.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  alignRight: propTypes.bool,
  isLink: propTypes.bool,
};
