'use client';
// assets
import dash from 'assets/icons/ic_dash.svg';
import left from 'assets/icons/ic_left.svg';
import quote from 'assets/icons/ic_quote.svg';
import right from 'assets/icons/ic_right.svg';
// third party
import { useRef } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

const TestimonialCarousel = (props) => {
  const { items } = props;
  const swiperRef = useRef(null);

  const changeProject = (swiper) => {
    const txts = document.querySelector('.animate-text');
    const active = swiper.realIndex + 1;
    animateText(txts, 0, [active - 1]);
  };

  const animateText = (
    element,
    elementIndex,
    indexText,
    textOutTimer = 100
  ) => {
    const newElement = element.children;
    const txtsLen = newElement.length;

    for (let i = 0; i < txtsLen; i++) {
      newElement[i].classList.remove('text-in', 'text-out');
    }

    newElement[indexText[elementIndex]].classList.add('text-in');

    setTimeout(function () {
      newElement[indexText[elementIndex]].classList.add('text-out');
    }, textOutTimer);

    if (indexText[elementIndex] === txtsLen - 1) {
      indexText[elementIndex] = 0;
    } else {
      indexText[elementIndex]++;
    }
  };

  return (
    <>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        autoHeight={true}
        ref={swiperRef}
        onTransitionEnd={changeProject}
      >
        {items.map((item, index) => (
          <SwiperSlide key={`item-${index}`}>
            <div className="relative rounded-2xl bg-gray p-6">
              <img src={quote} alt="Icon Quote" />
              <p className="mt-4 opacity-60">{item.content}</p>

              <h5 className="mb-2 mt-5 font-bold opacity-60">{item.name}</h5>
              <span className="opacity-60">{item.job}</span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="mt-6 flex items-center justify-between">
        <span className="flex items-center gap-3">
          <span className="animate-text">
            {items.map((_, index) => (
              <span
                key={index}
                data-index={index + 1}
                className={`${index === 0 ? 'text-in' : ''}`}
              >
                {index + 1}
              </span>
            ))}
          </span>
          <img src={dash} alt="Icon Dash" /> {items.length}
        </span>

        <div className="flex items-center gap-5">
          <img
            src={left}
            alt="Icon Prev"
            className="cursor-pointer"
            onClick={() => swiperRef.current.swiper.slidePrev()}
          />
          <img
            src={right}
            alt="Icon Next"
            className="cursor-pointer"
            onClick={() => swiperRef.current.swiper.slideNext()}
          />
        </div>
      </div>
    </>
  );
};

export default TestimonialCarousel;
