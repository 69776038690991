// components
import Heading from 'components/heading/Heading';
import ProjectCarousel from 'components/project-carousel/ProjectCarousel';
import ProjectItem from 'components/project-item/ProjectItem';
import Section from 'components/section/Section';
// data
import projects from 'data/projects.json';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import transition from 'transition';

const ProjectDetail = () => {
  const { slug } = useParams();
  const project = projects.find((project) => project.slug === slug);
  const otherProjects = projects.filter((project) => project.slug !== slug);

  return (
    <>
      <Helmet>
        <title>{project.name} | Aurora Web</title>
        <meta name="description" content={project.description} />
      </Helmet>

      {/* Banner */}
      <div className="relative h-[30vh] md:h-[50vh]">
        <img
          src={`/banner-projects/${project.banner}`}
          width={0}
          height={0}
          className="h-full w-full object-cover"
          alt="Banner"
        />
        <div className="absolute top-0 h-full w-full bg-black opacity-30"></div>
      </div>

      {/* Project */}
      <Section id="project" className="pt-32">
        <Heading
          title={project.name}
          description={<>{project.description}</>}
        />

        <div className="mb-5 flex flex-wrap justify-between gap-5">
          <ProjectItem
            title="SERVICE"
            description={project.service.join(' / ')}
          />

          <ProjectItem
            title="YEAR"
            description={project.year.toString()}
            alignRight
          />
        </div>

        <div className="mb-12 flex flex-wrap justify-between gap-5">
          <ProjectItem title="INDUSTRY" description={project.industry} />

          <ProjectItem
            title="WEBSITE"
            description={project.website}
            alignRight
            isLink
          />
        </div>

        <div className="lg:mx-14">
          {project.mockups.map((mockup, index) => (
            <img
              key={`mockup-${index}`}
              src={`/mockups/${mockup}`}
              alt={`Mockup ${project.name}`}
              width={0}
              height={0}
              sizes="100vh"
              className="mb-6 w-full"
            />
          ))}
        </div>
      </Section>

      {/* Works */}
      <Section id="works">
        <Heading
          title="See Other Project"
          description={
            <>
              Explore more of our work and discover our <br /> expertise.
            </>
          }
        />
        <ProjectCarousel items={otherProjects} />
      </Section>
    </>
  );
};

export default transition(ProjectDetail);
