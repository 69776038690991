import propTypes from 'prop-types';

const CardService = (props) => {
  const { title, description, categories, illustration } = props;

  return (
    <div className="card min-h-[400px]">
      <h2 className="mb-3 text-4xl font-bold">{title}</h2>
      <p className="mb-5 text-base-text opacity-60">{description}</p>
      <div className="flex flex-col items-start justify-start gap-3">
        {categories.map((category, index) => (
          <div key={`category-${index}`} className="badge-category">
            {category}
          </div>
        ))}
      </div>
      <div className="bottom-5 right-5 w-1/2 md:absolute md:w-1/3">
        {illustration}
      </div>
    </div>
  );
};

export default CardService;

CardService.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  categories: propTypes.array.isRequired,
  illustration: propTypes.element.isRequired,
};
