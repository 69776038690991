// assets
import behance from 'assets/icons/ic_behance.svg';
import dribble from 'assets/icons/ic_dribble.svg';
import instagram from 'assets/icons/ic_instagram.svg';
import linkedin from 'assets/icons/ic_linkedin.svg';
import tiktok from 'assets/icons/ic_tiktok.svg';
import youtube from 'assets/icons/ic_youtube.svg';
import logo from 'assets/icons/logo.svg';
import background from 'assets/images/bg-footer.svg';
// third party
import { Link } from 'react-router-dom';
// components
import Button from '../button/Button';
import SocialLink from '../social-link/SocialLink';

const Footer = () => {
  return (
    <footer
      id="footer"
      className="relative flex h-[90vh] w-full justify-center bg-accent lg:items-center"
    >
      <img
        src={background}
        className="absolute top-0 h-full w-full"
        alt="backgrond"
      />
      <div className="relative z-10 mt-32 flex flex-col items-center gap-3 text-center font-bold lg:mt-0">
        <h3 className="px-5 text-lg lg:text-2xl">
          Ready to turn your ideas into reality? Time to take action
        </h3>
        <h1 className="font-clash-display text-3xl lg:text-6xl">
          LET&apos;S BRING IT TO LIFE
        </h1>

        <div className="mt-8 flex w-full flex-wrap items-center gap-5 px-10">
          <Button
            type="link"
            className="w-full"
            href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=contact@auroraweb.id"
            isExternal
          >
            EMAIL
          </Button>
          <Button
            type="link"
            className="w-full"
            href="https://calendly.com/auroraweb/30min"
            isExternal
          >
            GET IN TOUCH
          </Button>
        </div>
      </div>
      <div className="absolute bottom-10 flex w-full flex-wrap items-center justify-between gap-5 px-5 md:px-10 lg:px-20">
        <Link href="/">
          <img src={logo} alt="Aurora Web" placeholder="blur" />
        </Link>
        <span className="font-bold opacity-60">
          All right reserved. Copyright © Aurora Web 2023.
        </span>
        <div className="flex items-center gap-5">
          <SocialLink href="https://www.instagram.com/auroraweb.id">
            <img src={instagram} alt="Instagram" />
          </SocialLink>
          <SocialLink href="">
            <img src={youtube} alt="Youtube" />
          </SocialLink>
          <SocialLink href="https://www.tiktok.com/@auroraaweb.id">
            <img src={tiktok} alt="Tiktok" />
          </SocialLink>
          <SocialLink href="https://www.linkedin.com/company/aurora-web-id/about">
            <img src={linkedin} alt="LinkedIn" />
          </SocialLink>
          <SocialLink href="https://dribbble.com/AuroraWeb/about">
            <img src={dribble} alt="Dribble" />
          </SocialLink>
          <SocialLink href="">
            <img src={behance} alt="Behance" />
          </SocialLink>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
