'use client';
// assets
import dash from 'assets/icons/ic_dash.svg';
import left from 'assets/icons/ic_left.svg';
import right from 'assets/icons/ic_right.svg';
// third party
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

const ProjectCarousel = (props) => {
  const { items } = props;
  const swiperRef = useRef(null);

  const changeProject = (swiper) => {
    const txts = document.querySelector('.animate-text');
    const active = swiper.realIndex + 1;
    animateText(txts, 0, [active - 1]);
  };

  const animateText = (
    element,
    elementIndex,
    indexText,
    textOutTimer = 100
  ) => {
    const newElement = element.children;
    const txtsLen = newElement.length;

    for (let i = 0; i < txtsLen; i++) {
      newElement[i].classList.remove('text-in', 'text-out');
    }

    newElement[indexText[elementIndex]].classList.add('text-in');

    setTimeout(function () {
      newElement[indexText[elementIndex]].classList.add('text-out');
    }, textOutTimer);

    if (indexText[elementIndex] === txtsLen - 1) {
      indexText[elementIndex] = 0;
    } else {
      indexText[elementIndex]++;
    }
  };

  return (
    <>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        ref={swiperRef}
        onTransitionEnd={changeProject}
      >
        {items.map((item) => (
          <SwiperSlide key={item.slug}>
            <Link
              to={`/project/${item.slug}`}
              className="group relative cursor-pointer overflow-hidden"
            >
              <img
                src={`/thumbnails/${item.thumbnail}`}
                alt={item.name}
                width={0}
                height={0}
                sizes="100vw"
                className="w-full transition-all duration-300 ease-in-out group-hover:scale-110"
              />
              <h2 className="absolute left-[5%] top-1/3 w-[40%] font-clash-display font-bold md:text-xl lg:text-4xl xl:text-5xl">
                {item.name}
              </h2>
              <span className="absolute bottom-10 left-[5%] hidden font-medium opacity-60 md:block">
                {item.service.join(' / ')}
              </span>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="mt-6 flex items-center justify-between">
        <span className="flex items-center gap-3">
          <span className="animate-text">
            {items.map((_, index) => (
              <span
                key={index}
                data-index={index + 1}
                className={`${index === 0 ? 'text-in' : ''}`}
              >
                {index + 1}
              </span>
            ))}
          </span>
          <img src={dash} alt="Icon Dash" /> {items.length}
        </span>

        <div className="flex items-center gap-5">
          <img
            src={left}
            alt="Icon Prev"
            className="cursor-pointer"
            onClick={() => swiperRef.current.swiper.slidePrev()}
          />
          <img
            src={right}
            alt="Icon Next"
            className="cursor-pointer"
            onClick={() => swiperRef.current.swiper.slideNext()}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectCarousel;
