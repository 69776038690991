import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SocialLink = (props) => {
  const { href, children } = props;

  return (
    <Link to={href} target="_blank" rel="noopener" className="icon">
      {children}
    </Link>
  );
};

export default SocialLink;

SocialLink.propTypes = {
  href: propTypes.string.isRequired,
};
