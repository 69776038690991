import propTypes from 'prop-types';

const CardWay = (props) => {
  const { number, title, description } = props;

  return (
    <div className="card flex min-h-[300px] flex-col justify-between">
      <div>
        <h2 className="mb-3 text-4xl font-bold">
          <span className="mr-3 opacity-60">{number}</span>
          {title}
        </h2>
        <p className="mb-5 text-base-text opacity-60">{description}</p>
      </div>
    </div>
  );
};

export default CardWay;

CardWay.propTypes = {
  number: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
};
