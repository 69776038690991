import propTypes from 'prop-types';

const Heading = (props) => {
  const { title, description } = props;

  return (
    <div className="mb-12 grid grid-cols-1 items-center justify-between gap-4 lg:grid-cols-2">
      <h1 className="font-clash-display text-5xl">{title}</h1>
      <p className="text-lg font-medium opacity-60 lg:text-right">
        {description}
      </p>
    </div>
  );
};

export default Heading;

Heading.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.element.isRequired,
};
