import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// layouts
import Default from 'layouts/Default';

// pages
import About from 'pages/About';
import Home from 'pages/Home';
import Project from 'pages/ProjectDetail';

const App = () => {
  const location = useLocation();
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Default />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="project/:slug" element={<Project />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </>
  );
};

export default App;
