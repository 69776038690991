// assets
import logo from 'assets/icons/logo.svg';
// third party
import { Link } from 'react-router-dom';
// components
import Button from '../button/Button';

const Navbar = () => {
  return (
    <nav className="fixed left-0 top-0 z-20 w-full">
      <div className="flex items-center justify-center px-5 py-4 md:justify-between md:px-10 lg:px-20">
        <Link to="/">
          <img src={logo} alt="Aurora Web" className="hidden md:block" />
        </Link>
        <div className="flex items-center gap-8 font-bold">
          <Link to="/#works">Works</Link>
          <Link to="/#services">Services</Link>
          <Link to="/about">About Us</Link>
        </div>
        <Button type="link" href="#footer" className="hidden md:inline-flex">
          Contact
        </Button>
      </div>
    </nav>
  );
};

export default Navbar;
