import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CardMember = (props) => {
  const { member } = props;

  return (
    <Link to={member.url} target="_blank" rel="noopener" className="group">
      <div className="relative mb-5 flex h-[400px] w-full flex-col justify-end overflow-hidden rounded-2xl bg-gray">
        <img
          src={`/images/${member.photo}`}
          alt={member.name}
          width={0}
          height={0}
          className="h-full w-full object-contain transition-all duration-300 group-hover:scale-110"
        />
      </div>

      <h3 className="text-lg font-bold text-white">{member.name}</h3>
      <span className="font-medium opacity-60">{member.job}</span>
    </Link>
  );
};

export default CardMember;

CardMember.propTypes = {
  member: propTypes.object.isRequired,
};
